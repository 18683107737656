<template>
  <div id="UavManage">
    <Table
      :loading="loading"
      max-height="740"
      :columns="columns"
      :data="UAVdata"
    ></Table>
    <Page
      :total="total"
      show-total
      :page-size="limit"
      style="text-align: center; margin-top: 5px"
      :current="parseInt(page)"
      @on-change="changePage"
    />
    <Modal v-model="compileModal" fullscreen class-name="compileUAV-modal">
      <p slot="header" style="text-align: center">
        <span style="font-size: 20px">编辑无人机信息</span>
      </p>
      <div class="content">
        <Form ref="compileUav" :model="compileUav" label-position="top">
          <div class="container">
            <div class="left">
              <div class="UAVimg">
                <img :src="compileUav.image" alt="" />
              </div>
              <h1>保险类型</h1>
              <FormItem label="是否有保险">
                <RadioGroup v-model="compileUav.is_insurance">
                  <Radio :label="Number(1)">有保险</Radio>
                  <Radio :label="Number(0)">无保险</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="保险公司" prop="name">
                <Input
                  v-model="compileUav.insu_company"
                  :disabled="compileUav.is_insurance == 0"
                  maxlength="30"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="保险单号" prop="name">
                <Input
                  v-model="compileUav.insu_no"
                  :disabled="compileUav.is_insurance == 0"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <div style="display: flex; justify-content: space-between">
                <FormItem label="购买日期" style="width: 45%" prop="name">
                  <DatePicker
                    type="date"
                    placeholder="Select date"
                    :editable="false"
                    v-model="compileUav.buydate"
                    style="width: 100%"
                    :disabled="compileUav.is_insurance == 0"
                  ></DatePicker>
                </FormItem>
                <FormItem label="有效期" style="width: 45%" prop="name">
                  <DatePicker
                    type="date"
                    placeholder="Select date"
                    :editable="false"
                    v-model="compileUav.validityday"
                    style="width: 100%"
                    :disabled="compileUav.is_insurance == 0"
                  ></DatePicker>
                </FormItem>
              </div>
              <FormItem label="保险种类" prop="name">
                <Input
                  v-model="compileUav.insutype"
                  :disabled="compileUav.is_insurance == 0"
                  maxlength="30"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="销售公司" prop="name">
                <Input
                  v-model="compileUav.sales_company"
                  :disabled="compileUav.is_insurance == 0"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
            </div>
            <div class="Divider"></div>
            <div class="right">
              <h1>基本信息</h1>
              <FormItem label="单位名称" prop="name">
                <!-- <Input
                  v-model="compileUav.device_name"
                  maxlength="30"
                  show-word-limit
                ></Input> -->
                <TeamListCascader
                  @change_Team="changeTeamData"
                  class="addTeamInput"
                  style="width: 100%"
                  ref="TeamListCascader"
                />
              </FormItem>
              <!-- <FormItem label="品牌"
                        prop="name">
                <Input v-model="compileUav.brand"
                       maxlength="30"
                       show-word-limit></Input>
              </FormItem> -->
              <FormItem label="型号" prop="name">
                <Cascader v-model="compileUav.models" :data="UAVTypeList">
                  <!-- <Option v-for="(item,index) in UAVTypeList"
                          :value="item"
                          :key="index">{{
                    item
                  }}</Option> -->
                </Cascader>
              </FormItem>
              <FormItem label="产品序列号" prop="name">
                <Input
                  v-model="compileUav.serial_code"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="飞控序列号" prop="name">
                <Input
                  v-model="compileUav.flycontrol"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="无人机类型" prop="name">
                <Select v-model="compileUav.uav_type">
                  <Option value="多旋翼">多旋翼</Option>
                  <Option value="固定翼">固定翼</Option>
                  <Option value="垂直起降">垂直起降</Option>
                </Select>
              </FormItem>
              <FormItem label="中国民航局民用无人机登记号" prop="name">
                <Input
                  v-model="compileUav.registration"
                  maxlength="40"
                  show-word-limit
                ></Input>
              </FormItem>
              <FormItem label="标注" prop="remark">
                <Input
                  v-model="compileUav.remark"
                  maxlength="20"
                  show-word-limit
                ></Input>
              </FormItem>
            </div>
          </div>
        </Form>
      </div>
      <div slot="footer" style="text-align: center">
        <Button
          type="info"
          size="large"
          style="width: 300px; letter-spacing: 4px"
          @click="compile_ok"
          >确认编辑</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import TeamListCascader from '@/components/statistics/commonComponents/TeamCascader_task.vue'
import {
  DeviceList,
  DeviceType,
  DeviceUpdate,
  DeviceDel,
} from '@/utils/javaApi.js'
export default {
  name: 'UavManage',
  props: ['teamID'],
  components: {TeamListCascader},
  watch: {
    teamID: function () {
      this.loadUavList()
    },
  },
  data: function () {
    return {
      userType: '',
      userInfo: {},
      Project1info: {},
      columns: [
        {
          title: '单位名称',
          key: 'device_name',
          align: 'center',
        },

        {
          title: '品牌',
          key: 'brand',
          width: 80,
          align: 'center',
        },
        {
          title: '型号',
          key: 'model',
          width: 180,
          align: 'center',
        },
        {
          title: '产品序列号',
          key: 'serial_code',
          align: 'center',
        },
        {
          title: '标注',
          key: 'remark',
          align: 'center',
          width: 180
        },
        {
          title: '总飞行次数',
          key: 'flycount',
          align: 'center',
          width: 120,
        },
        {
          title: '总飞行时长',
          key: 'flytime',
          align: 'center',
          render: (h, params) => {
            let seconds = params.row.flytime
            if (parseInt(seconds) > 0) {
              let hour = parseInt(seconds / 3600)
              let minute = parseInt((seconds - 3600 * hour) / 60)
              seconds = seconds - 3600 * hour - 60 * minute

              return h(
                'span',
                (hour ? hour + '小时' : '') +
                  (minute ? minute + '分' : '') +
                  seconds +
                  '秒'
              )
            } else {
              return h('span', '0秒')
            }
          },
        },
        {
          title: '最后使用',
          key: 'lastusetime',
          width: 180,
          align: 'center',
        },
        {
          title: '保险期限',
          key: 'is_insurance',
          width: 180,
          align: 'center',
          render: (h, params) => {
            let state = ''
            let css = ''
            if (params.row.is_insurance == 0) {
              state = '无保险'
              css = '#DB851E'
            } else {
              let daytime = new Date() //获取当前日期
              let time = new Date()
              let endTime = params.row.validityday.split('-')
              let validityday = time.setFullYear(
                endTime[0],
                endTime[1] - 1,
                endTime[2]
              )
              if (validityday < daytime.getTime()) {
                state = '已过期'
                css = '#F56C6C'
              } else {
                state = params.row.validityday
                css = '#00ED6D'
              }
            }
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: css,
                  },
                },
                state
              ),
            ])
          },
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'button',
                {
                  style: {
                    marginRight: '10px',
                    width: '55px',
                    height: '28px',
                    background: '#2196F3',
                    color: '#fff',
                  },
                  on: {
                    click: () => {
                      if (this.userInfo.is_manager === 1) {
                        // this.nowUavData = params.row;
                        this.$refs.TeamListCascader.setInput(params.row.device_name)
                        this.compileUav = params.row
                        this.compileUav.models = []
                        this.compileUav.models.push(
                          params.row.brand,
                          Number(params.row.device_class_id)
                        )
                        console.log('查看详情', this.compileUav)
                        this.compileModal = true
                      } else {
                        this.$Message.warning('您没有操作权限')
                      }
                    },
                  },
                },
                '编辑'
              ),
              h(
                'button',
                {
                  style: {
                    marginRight: '10px',
                    width: '55px',
                    height: '28px',
                    background: '#F56C6C',
                    color: '#fff',
                  },
                  on: {
                    click: () => {
                      if (this.userInfo.is_manager === 1) {
                        this.$Modal.confirm({
                          title: '系统提示',
                          content: `是否删除  <span style="color: red">${params.row.device_name}</span>! ,一旦删除无法恢复?`,
                          onCancel: () => {
                            this.$Message.info('点击了取消')
                          },
                          onOk: () => {
                            this.removeUAV(params.row.id)
                          },
                        })
                      } else {
                        this.$Message.warning('您没有操作权限')
                      }
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      UAVdata: [],
      loading: false,
      // 编辑弹框
      compileModal: false,
      UAVTypeList: [],
      compileUav: {},
      // compileUav_ruleInline: {//暂时不需要做限制
      //     device_name: [
      //         { required: true, message: '请填写账号',trigger: 'blur'},
      //         { type: 'string',min: 6, max:16,pattern:/[a-zA-Z\d]$/, message:'请使用长度为 6 - 16个字符的字母与数字'},
      //     ],
      //     name: [
      //         { required: true, message: '请填写用户名',trigger: 'blur'},
      //         { min: 4,max:12, message: '用户名长度 4 - 12个字符' }
      //     ],
      //     password: [
      //         { required: true, message: '请填写管理员密码',trigger: 'blur'},
      //         { type: 'string',min: 6, max:16,pattern:/[a-zA-Z\d]$/, message: '请使用长度为 6 - 16个字符的字母与数字'}
      //     ]
      // },
      //   nowUavData: null, //点击编辑后当前无人机信息
      total: 0, // 数据长度
      team_id: '',
      page: 1,
      limit: 14,
    }
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))
    this.loadUavList()
    // 获取无人机全部型号
    this.$post(DeviceType()).then((res) => {
      if (res.code == 1) {
        res.data.forEach((item, index) => {
          item.label = item.brand
          item.value = item.brand
          item.children.map((el) => {
            el.label = el.model
            el.value = el.classId
          })
        })
        this.UAVTypeList = res.data
      } else {
        this.$Message.error(res.msg_customer)
      }
    })
    //注册重新加载列表数据
    this.$_bus.$off('loadUavList')
    this.$_bus.$on('loadUavList', (val) => {
      if (val) {
        this.loadUavList()
      }
    })
  },
  methods: {
    changeTeamData(val, data) {
      this.compileUav.device_name = data[0].label
    },
    //加载无人机数据列表
    loadUavList() {
      this.loading = true
      this.$post(DeviceList(), {
        team_id: this.teamID,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.UAVdata = []
        this.loading = false
        if (res.code == 1) {
          this.total = res.data.data.total
          this.UAVdata = res.data.data.list
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 翻页
    changePage(ind) {
      this.page = ind
      this.loadUavList()
    },
    // 编辑
    compile_ok() {
      // this.$refs["compileUav"].validate((valid) => {
      //     if(valid){
      //
      //     }
      // })
      var data = {
        id: this.compileUav.id,
        device_name: this.compileUav.device_name,
        serial_code: this.compileUav.serial_code,
        // brand: this.compileUav.brand,
        flycontrol: this.compileUav.flycontrol,
        remark: this.compileUav.remark,
        class_id: this.compileUav.models[1],
        device_insurance_id: this.compileUav.device_insurance_id,
        uav_type: this.compileUav.uav_type,
        is_insurance: this.compileUav.is_insurance,
        registration: this.compileUav.registration,
      }

      if (data.is_insurance == 1) {
        if (this.compileUav.insu_company == '') {
          this.$Message.warning('保险公司不能为空')
          return
        }
        if (this.compileUav.insu_no == '') {
          this.$Message.warning('保险单号不能为空')
          return
        }
        if (this.compileUav.insutype == '') {
          this.$Message.warning('保险类型不能为空')
          return
        }
        if (this.compileUav.sales_company == '') {
          this.$Message.warning('保险销售公司不能为空')
          return
        }
        data.insu_company = this.compileUav.insu_company
        data.insu_no = this.compileUav.insu_no
        data.insutype = this.compileUav.insutype
        data.sales_company = this.compileUav.sales_company
        data.buydate = this.$moment(this.compileUav.buydate).format(
          'YYYY-MM-DD'
        )
        data.validityday = this.$moment(this.compileUav.validityday).format(
          'YYYY-MM-DD'
        )

        if (data.buydate == 'Invalid date') {
          this.$Message.warning('保险购买日期不能为空')
          return
        }
        if (data.validityday == 'Invalid date') {
          this.$Message.warning('保险有效期不能为空')
          return
        }
      }
      this.$post(DeviceUpdate(), data).then((res) => {
        if (res.code == 1) {
          this.loadUavList()
          this.compileUav = {}
          this.compileModal = false
          this.$Message.info(res.msg_customer)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 删除
    removeUAV(id) {
      if (this.userInfo.is_manager === 1) {
        this.$post(DeviceDel(), {
          id: id,
        }).then((res) => {
          if (res.code == 1) {
            console.log(res)
            this.loadUavList()
            this.$Message.info(res.msg_customer)
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      } else {
        this.$Message.warning('您没有操作权限')
      }
    },
  },
}
</script>

<style scoped lang="scss">
#UavManage {
  width: 100%;
  height: 100%;
  //   background-color: #fff;
  //   overflow: auto;
  //   box-shadow: 0px 3px 6px #ddd;
  color: #fff;
  border-radius: 4px;
  padding: 15px 20px;
  position: relative;
  /deep/ .ivu-table th {
    background: #333;
    color: #fff;
  }
  /deep/ .ivu-table {
    background: unset;
  }
  /deep/ .ivu-table td {
    background: #1e1e1e;
    color: #fff;
  }
  /deep/ .ivu-table td,
  .ivu-table th {
    border-bottom: 1px solid #000;
  }
  /deep/ .ivu-table th {
    border-bottom: 1px solid #000;
  }
}
/deep/.compileUAV-modal {
  .ivu-modal-body {
    padding: 0;
  }
  .content {
    padding: 10px 20px;
    .container {
      width: 100%;
      display: flex;
    }
    .left {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
      .UAVimg {
        width: 100%;
        height: 244px;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
          width: 428px;
          height: auto;
        }
      }
    }
    .Divider {
      width: 2px;
      margin: 10px 20px;
      background-color: #f8f8f9;
    }
    .right {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
    }
  }
  .ivu-modal-footer {
    padding: 10px 18px 10px 18px;
  }
}
</style>
